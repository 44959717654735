import { useContext, useEffect, useState } from 'react';
import { Button, Dropdown, Form, FormDropdown, FormGroup, FormInput, Header, Icon, Image, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from 'semantic-ui-react';
import { v4 as uuidv4 } from 'uuid';
import { htmlToText, isUndefinedOrNull, sortArrayByElement } from '../Service/Helpers';
import { getAdminData, postData, postFile, postImage } from '../Service/Request';
import { LocalStorage } from './../Service/LocalStorage';
import { DropdownButton, Reorder, RichTextEditor, UploadImage } from './../Components';

function AdminRGB() {
    const [btnReset, setBtnReset] = useState('');
    const [uploadedImageData, setUploadedImageData] = useState();
    const [dataToDisplay, setDataToDisplay] = useState(null);
    const [reset, setReset] = useState(false);
    const [dataForOrder, setDataForOrder] = useState([]);
    const [description, setDescription] = useState('');
    const [university, setUniversity] = useState('');
    const [dropdownList, setDropdownList] = useState([]);
    const [selectedValueForEdit, setSelectedValueForEdit] = useState({});
    const [category, setCategory] = useState('');
    const [catValue, setCatValue] = useState('');
    const [operation, setOperation] = useState('');
    const [orderOptions, setOrderOptions] = useState([]);
    const [fileData, setFileData] = useState();
    const { token: [token, setToken] } = useContext(LocalStorage);
    const { isLoaderActive: [, setIsLoaderActive] } = useContext(LocalStorage);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        let body = {
            token: token
        }
        setIsLoaderActive(true);
        getAdminData('adminRgb', body).then(rgbRes => {
            if (!isUndefinedOrNull(rgbRes)) {
                setDataToDisplay(rgbRes);
                resetAll();
                setOperation('');
                setCategory('');
            }
            else {
                setToken(null);
            }
            setIsLoaderActive(false);
        });
    }

    const onBtnClick = (op, cat) => {
        setOperation(op);
        setCategory(cat);
        let catVal = (cat === 'Academic position' ? 'academicPositions' : (cat === 'Research position' ? 'researchPositions' : (cat === 'Recognition' ? 'recognitions' : (cat === 'Projects handled' ? 'projectsHandled' : (cat === 'Profile data' ? 'profileData' : '')))));
        setCatValue(catVal);
        if (op === 'order') {
            setDataForOrder(sortArrayByElement(dataToDisplay[catVal], 'order').map((x, i) => { return { title: x.title, id: x.id, order: x.order, newOrder: x.order, DOILink: x.DOILink, DOIText: x.DOIText } }));
        }
        else if (op === 'delete' || (op === 'edit' && cat !== 'Profile data')) {
            if (cat === 'Academic position' || cat === 'Research position') {
                setDropdownList(sortArrayByElement(dataToDisplay[catVal], cat === 'Academic position' ? 'from' : 'year').map(x => {
                    return {
                        key: x.id,
                        text: x.position,
                        value: x.id,
                        description: cat === 'Academic position' ? (x.from + ' - ' + x.to) : x.year
                    }
                }));
            }
            else {
                setDropdownList(dataToDisplay[catVal].map(x => {
                    return {
                        key: x.id,
                        text: htmlToText(x.title),
                        value: x.id
                    }
                }));
            }
        }
        resetAll();
        if (cat === 'Profile data') {
            setDescription(dataToDisplay.profileData.description);
            setUniversity(dataToDisplay.profileData.university);
            setReset(false);
            setDataForOrder(sortArrayByElement(dataToDisplay.profileData.education, 'order').map(x => {
                return { id: x.id, title: x.title, order: x.order, newOrder: x.order }
            }));
        }
    }

    const onEditDeleteItemSelection = (value) => {
        if (value !== '') {
            let obj = dataToDisplay[catValue].find(x => x.id === value)
            setSelectedValueForEdit(obj);
            if (category === 'Academic position' || category === 'Research position') {
                setDescription(obj.description);
            }
            setReset(false);
        }
        else {
            setSelectedValueForEdit({});
            setDescription('');
            setReset(true);
        }
        document.getElementById('inputForm')?.reset();
    }

    const onOrderSelection = (value, titleId) => {
        if (value !== '') {
            setDataForOrder(dataForOrder.map(x => {
                if (x.id === titleId) {
                    x.newOrder = value;
                }
                return x
            }));
            setOrderOptions(orderOptions.filter(x => x.value !== value));
        }
        else {
            let removedOrderNo = dataForOrder.find(x => x.id === titleId).newOrder;
            setOrderOptions(sortArrayByElement([...orderOptions, ...[{ key: removedOrderNo, text: removedOrderNo, value: removedOrderNo }]], 'value'));
            setDataForOrder(dataForOrder.map(x => {
                if (x.id === titleId) {
                    x.newOrder = 0;
                }
                return x
            }));
        }
    }

    const onRemoveEducation = (titleId) => {
        setDataForOrder(dataForOrder.filter(x => x.id !== titleId).map((z, i) => {
            z.newOrder = i + 1;
            z.order = i + 1;
            return z
        }));
        setOrderOptions([]);
    }

    const onAddEducation = () => {
        let val = document.getElementById('education').value;
        if (!isUndefinedOrNull(val) && val !== '') {
            let orderNo = dataForOrder.length + 1;
            setDataForOrder([...dataForOrder, ...[{ id: uuidv4(), title: val, order: orderNo, newOrder: orderNo }]]);
            document.getElementById('education').value = '';
        }
    }

    const onSave = (data = null) => {
        setIsLoaderActive(true);
        if (operation === 'add') {
            if (catValue === 'academicPositions') {
                let fromYear = document.getElementById('fromYear').value;
                let toYear = document.getElementById('toYear').value;
                let position = document.getElementById('position').value;
                if (!isUndefinedOrNull(fromYear) && !isUndefinedOrNull(toYear) && !isUndefinedOrNull(position) && !isUndefinedOrNull(htmlToText(description))) {
                    let body = {
                        token: token,
                        category: catValue,
                        operation: operation,
                        data: {
                            from: fromYear,
                            to: toYear,
                            position: position,
                            description: description,
                            id: uuidv4()
                        }
                    }
                    postData('postRGB', body).then(postRgbRes => {
                        if (!isUndefinedOrNull(postRgbRes)) {
                            alert('Successfully submitted.');
                            loadData();
                        }
                        else {
                            setToken(null);
                        }
                    });
                }
                else {
                    setIsLoaderActive(false);
                    alert('Fill all the required fields.');
                }
            }
            else if (catValue === 'researchPositions') {
                let year = document.getElementById('year').value;
                let position = document.getElementById('position').value;
                if (!isUndefinedOrNull(year) && !isUndefinedOrNull(position) && !isUndefinedOrNull(htmlToText(description))) {
                    let body = {
                        token: token,
                        category: catValue,
                        operation: operation,
                        data: {
                            year: year,
                            position: position,
                            description: description,
                            id: uuidv4()
                        }
                    }
                    postData('postRGB', body).then(postRgbRes => {
                        if (!isUndefinedOrNull(postRgbRes)) {
                            alert('Successfully submitted.');
                            loadData();
                        }
                        else {
                            setToken(null);
                        }
                    });
                }
                else {
                    setIsLoaderActive(false);
                    alert('Fill all the required fields.');
                }
            }
            else {
                if (!isUndefinedOrNull(htmlToText(description))) {
                    let body = {
                        token: token,
                        category: catValue,
                        operation: operation,
                        data: {
                            title: description,
                            order: dataToDisplay[catValue].length + 1,
                            id: uuidv4()
                        }
                    }
                    postData('postRGB', body).then(postRgbRes => {
                        if (!isUndefinedOrNull(postRgbRes)) {
                            alert('Successfully submitted.');
                            loadData();
                        }
                        else {
                            setToken(null);
                        }
                    });
                }
                else {
                    setIsLoaderActive(false);
                    alert('Fill all the required fields.');
                }
            }
        }
        else if (operation === 'edit') {
            if (catValue === 'academicPositions') {
                let fromYear = document.getElementById('fromYear').value;
                let toYear = document.getElementById('toYear').value;
                let position = document.getElementById('position').value;
                if (!isUndefinedOrNull(fromYear) && !isUndefinedOrNull(toYear) && !isUndefinedOrNull(position) && !isUndefinedOrNull(htmlToText(description))) {
                    if (selectedValueForEdit.from !== fromYear || selectedValueForEdit.to !== toYear || selectedValueForEdit.position !== position || selectedValueForEdit.description !== description) {
                        let body = {
                            token: token,
                            category: catValue,
                            operation: operation,
                            data: {
                                from: fromYear,
                                to: toYear,
                                position: position,
                                description: description,
                                id: selectedValueForEdit.id
                            }
                        }
                        postData('postRGB', body).then(postRgbRes => {
                            if (!isUndefinedOrNull(postRgbRes)) {
                                alert('Successfully submitted.');
                                loadData();
                            }
                            else {
                                setToken(null);
                            }
                        });
                    }
                    else {
                        setIsLoaderActive(false);
                        alert('No changes made to submit.');
                    }
                }
                else {
                    setIsLoaderActive(false);
                    alert('Fill all the required fields.');
                }
            }
            else if (catValue === 'researchPositions') {
                let year = document.getElementById('year').value;
                let position = document.getElementById('position').value;
                if (!isUndefinedOrNull(year) && !isUndefinedOrNull(position) && !isUndefinedOrNull(htmlToText(description))) {
                    if (selectedValueForEdit.year !== year || selectedValueForEdit.position !== position || selectedValueForEdit.description !== description) {
                        let body = {
                            token: token,
                            category: catValue,
                            operation: operation,
                            data: {
                                year: year,
                                position: position,
                                description: description,
                                id: selectedValueForEdit.id
                            }
                        }
                        postData('postRGB', body).then(postRgbRes => {
                            if (!isUndefinedOrNull(postRgbRes)) {
                                alert('Successfully submitted.');
                                loadData();
                            }
                            else {
                                setToken(null);
                            }
                        });
                    }
                    else {
                        setIsLoaderActive(false);
                        alert('No changes made to submit.');
                    }
                }
                else {
                    setIsLoaderActive(false);
                    alert('Fill all the required fields.');
                }
            }
            else if (catValue === 'profileData') {
                if (!isUndefinedOrNull(dataForOrder) && !isUndefinedOrNull(dataForOrder.find(x => x.newOrder === 0))) {
                    setIsLoaderActive(false);
                    alert('Some data with no order, please fill that to save');
                }
                else {
                    let phone = document.getElementById('phone')?.value;
                    let email = document.getElementById('email')?.value;
                    let position = document.getElementById('position')?.value;
                    let department = document.getElementById('department')?.value;
                    let scrollingText = document.getElementById('scrollingText')?.value;
                    let isOrderChanged = !isUndefinedOrNull(dataForOrder.find(x => x.order !== x.newOrder));
                    let eduIds = dataToDisplay.profileData.education.map(x => x.id);
                    let isEducationChanged = dataToDisplay.profileData.education.length !== dataForOrder.length || !isUndefinedOrNull(dataForOrder.find(x => !eduIds.includes(x.id)));
                    if (!isUndefinedOrNull(htmlToText(university)) && !isUndefinedOrNull(htmlToText(description)) && phone !== '' && email !== '' && position !== ''
                        && department !== '' && !isUndefinedOrNull(dataForOrder)) {
                        if (dataToDisplay.profileData.university !== university || dataToDisplay.profileData.description !== description || dataToDisplay.profileData.phone !== phone || dataToDisplay.profileData.email !== email
                            || dataToDisplay.profileData.position !== position || dataToDisplay.profileData.department !== department || dataToDisplay.profileData.scrollingText !== scrollingText ||
                            isOrderChanged || isEducationChanged || !isUndefinedOrNull(uploadedImageData) || !isUndefinedOrNull(fileData)) {
                            let eduData = [];
                            if (!isUndefinedOrNull(dataForOrder)) {
                                eduData = sortArrayByElement(dataForOrder, 'newOrder').map(x => {
                                    return { name: x.title, order: x.newOrder, id: uuidv4() }
                                });
                            }
                            let body = {
                                token: token,
                                category: catValue,
                                operation: operation,
                                data: {
                                    department: department,
                                    description: description,
                                    education: eduData,
                                    email: email,
                                    image: dataToDisplay.profileData.image,
                                    name: 'Prof. R Geetha Balakrishna',
                                    phone: phone,
                                    position: position,
                                    scrollingText: scrollingText,
                                    university: university,
                                    doc: dataToDisplay.profileData.doc
                                }
                            }
                            let formData = new FormData();
                            if (isUndefinedOrNull(uploadedImageData) && isUndefinedOrNull(fileData)) {
                                postData('postRGB', body).then(postRgbRes => {
                                    if (!isUndefinedOrNull(postRgbRes)) {
                                        alert('Successfully submitted.');
                                        loadData();
                                    }
                                    else {
                                        setToken(null);
                                    }
                                });
                            }
                            else {
                                if (!isUndefinedOrNull(uploadedImageData)) {
                                    let delBody = {
                                        token: token,
                                        image: [dataToDisplay.profileData.image]
                                    }
                                    postData('deleteImage', delBody).then(postDelImgRes => {
                                        if (!isUndefinedOrNull(postDelImgRes)) {
                                            formData = new FormData();
                                            formData.append('image', uploadedImageData, uploadedImageData.name);
                                            formData.append('token', token);
                                            postImage(formData).then(postImgRes => {
                                                if (!isUndefinedOrNull(postImgRes)) {
                                                    body.data.image = postImgRes.image;
                                                    if (!isUndefinedOrNull(fileData)) {
                                                        formData = new FormData();
                                                        formData.append('file', fileData, fileData.name);
                                                        formData.append('token', token);
                                                        postFile(formData).then(postFileRes => {
                                                            if (!isUndefinedOrNull(postFileRes)) {
                                                                body.data.doc = postFileRes.file;
                                                                postData('postRGB', body).then(postRgbRes => {
                                                                    if (!isUndefinedOrNull(postRgbRes)) {
                                                                        alert('Successfully submitted.');
                                                                        loadData();
                                                                    }
                                                                    else {
                                                                        setToken(null);
                                                                    }
                                                                });
                                                            }
                                                            else {
                                                                setToken(null);
                                                            }
                                                        });
                                                    }
                                                    else {
                                                        postData('postRGB', body).then(postRgbRes => {
                                                            if (!isUndefinedOrNull(postRgbRes)) {
                                                                alert('Successfully submitted.');
                                                                loadData();
                                                            }
                                                            else {
                                                                setToken(null);
                                                            }
                                                        });
                                                    }
                                                }
                                                else {
                                                    setToken(null);
                                                }
                                            });
                                        }
                                        else {
                                            setToken(null);
                                        }
                                    });
                                }
                                else {
                                    if (!isUndefinedOrNull(fileData)) {
                                        formData = new FormData();
                                        formData.append('file', fileData, fileData.name);
                                        formData.append('token', token);
                                        postFile(formData).then(postFileRes => {
                                            if (!isUndefinedOrNull(postFileRes)) {
                                                body.data.doc = postFileRes.file;
                                                postData('postRGB', body).then(postRgbRes => {
                                                    if (!isUndefinedOrNull(postRgbRes)) {
                                                        alert('Successfully submitted.');
                                                        loadData();
                                                    }
                                                    else {
                                                        setToken(null);
                                                    }
                                                });
                                            }
                                            else {
                                                setToken(null);
                                            }
                                        });
                                    }
                                    else {
                                        postData('postRGB', body).then(postRgbRes => {
                                            if (!isUndefinedOrNull(postRgbRes)) {
                                                alert('Successfully submitted.');
                                                loadData();
                                            }
                                            else {
                                                setToken(null);
                                            }
                                        });
                                    }
                                }
                            }
                        }
                        else {
                            setIsLoaderActive(false);
                            alert('No changes to save.');
                        }
                    }
                    else {
                        setIsLoaderActive(false);
                        alert('Fill required fields.');
                    }
                }
            }
            else {
                if (!isUndefinedOrNull(htmlToText(description))) {
                    if (selectedValueForEdit.description !== description) {
                        let body = {
                            token: token,
                            category: catValue,
                            operation: operation,
                            data: {
                                title: description,
                                order: selectedValueForEdit.order,
                                id: selectedValueForEdit.id
                            }
                        }
                        postData('postRGB', body).then(postRgbRes => {
                            if (!isUndefinedOrNull(postRgbRes)) {
                                alert('Successfully submitted.');
                                loadData();
                            }
                            else {
                                setToken(null);
                            }
                        });
                    }
                    else {
                        setIsLoaderActive(false);
                        alert('No changes made to submit.');
                    }
                }
                else {
                    setIsLoaderActive(false);
                    alert('Fill all the required fields.');
                }
            }
        }
        else if (operation === 'delete') {
            let body = {
                token: token,
                category: catValue,
                operation: operation,
                data: {
                    id: selectedValueForEdit.id
                }
            }
            postData('postRGB', body).then(postRgbRes => {
                if (!isUndefinedOrNull(postRgbRes)) {
                    alert('Successfully submitted.');
                    loadData();
                }
                else {
                    setToken(null);
                }
            });
        }
        else if (operation === 'order') {
            if (isUndefinedOrNull(data.find(x => x.newOrder === 0))) {
                let changedOrder = data.filter(x => x.newOrder !== x.order).map(y => { return { id: y.id, order: y.newOrder } });
                if (!isUndefinedOrNull(changedOrder)) {
                    let cat = catValue;
                    let newOrderedData = [];
                    changedOrder.forEach(x => {
                        let orderedData = dataToDisplay[cat].find(y => y.id === x.id);
                        orderedData.order = x.order;
                        newOrderedData.push(orderedData);
                    });
                    let body = {
                        token: token,
                        category: cat,
                        operation: operation,
                        data: newOrderedData
                    }
                    postData('postRGB', body).then(postRgbRes => {
                        if (!isUndefinedOrNull(postRgbRes)) {
                            alert('Successfully submitted.');
                            loadData();
                        }
                        else {
                            setToken(null);
                        }
                    });
                }
                else {
                    setIsLoaderActive(false);
                    alert('No changes made to submit.');
                }
            }
            else {
                setIsLoaderActive(false);
                alert('Some data with no order, please fill that to save');
            }
        }
    }

    const resetAll = () => {
        setSelectedValueForEdit({});
        setOrderOptions([]);
        setDataForOrder([]);
        setBtnReset('');
        setReset(true);
        setDescription('');
        setFileData(null);
        setUploadedImageData(null);
        document.getElementById('inputForm')?.reset();
        if (!isUndefinedOrNull(document.querySelector('.dropdown.icon.clear'))) {
            document.querySelector('.dropdown.icon.clear').click();
        }
    }

    const onRemoveFile = () => {
        setFileData(null);
        document.getElementById('file').value = '';
    }

    return (
        <>
            {!isUndefinedOrNull(dataToDisplay) &&
                <div>
                    <div className='buttonsGroup'>
                        <DropdownButton btnReset={btnReset} onChange={(op, cat) => onBtnClick(op, cat)} page='rgb' btn='add' />
                        <DropdownButton btnReset={btnReset} onChange={(op, cat) => onBtnClick(op, cat)} page='rgb' btn='edit' />
                        <DropdownButton btnReset={btnReset} onChange={(op, cat) => onBtnClick(op, cat)} page='rgb' btn='delete' />
                        <DropdownButton btnReset={btnReset} onChange={(op, cat) => onBtnClick(op, cat)} page='rgb' btn='order' />
                    </div>
                    <div className='editContainer'>
                        <Header as='h2'>
                            {operation.toUpperCase() + ' ' + category.toUpperCase()}
                        </Header>
                        {(operation === 'add' || (operation === 'edit' && category !== 'Profile data')) &&
                            <>
                                {operation === 'edit' &&
                                    <Form className='adminInputContainer' autocomplete='off'>
                                        <FormDropdown
                                            placeholder={'Select ' + category.toLowerCase()} label={category}
                                            className='adminInput' fluid selection closeOnChange closeOnEscape closeOnBlur clearable search required
                                            options={dropdownList} selectOnBlur={false} upward={false} width='16'
                                            onChange={(event, data) => onEditDeleteItemSelection(data.value)}
                                            disabled={isUndefinedOrNull(dropdownList) || dropdownList.length === 0} />
                                    </Form>
                                }
                                {(operation === 'add' || (operation === 'edit' && !isUndefinedOrNull(selectedValueForEdit))) &&
                                    <>
                                        <Form className='adminInputContainer' id='inputForm' autocomplete='off'>
                                            {(category === 'Academic position' || category === 'Research position') &&
                                                <>
                                                    <FormGroup widths='equal'>
                                                        <FormInput fluid label='Position' id='position' type='text' placeholder='Enter position' required className='adminInput textInput' defaultValue={!isUndefinedOrNull(selectedValueForEdit) ? selectedValueForEdit.position : ''} />
                                                        {category === 'Academic position' &&
                                                            <>
                                                                <FormInput fluid label='From year' id='fromYear' type='text' placeholder='Enter from year' required className='adminInput' defaultValue={!isUndefinedOrNull(selectedValueForEdit) ? selectedValueForEdit.from : ''} />
                                                                <FormInput fluid label='To year' id='toYear' type='text' placeholder='Enter to year' required className='adminInput' defaultValue={!isUndefinedOrNull(selectedValueForEdit) ? selectedValueForEdit.to : ''} />
                                                            </>
                                                        }
                                                        {category === 'Research position' &&
                                                            <>
                                                                <FormInput fluid label='Year' id='year' type='text' placeholder='Enter year' required className='adminInput' defaultValue={!isUndefinedOrNull(selectedValueForEdit) ? selectedValueForEdit.year : ''} />
                                                            </>
                                                        }
                                                    </FormGroup>
                                                    <RichTextEditor data={!isUndefinedOrNull(selectedValueForEdit) ? selectedValueForEdit.description : ''} id='details' placeholder='Enter details' label='Details' required={true} onChange={(value) => { setDescription(value); setReset(false); }} reset={reset} />
                                                </>
                                            }
                                            {(category === 'Recognition' || category === 'Projects handled') &&
                                                <>
                                                    <RichTextEditor data={!isUndefinedOrNull(selectedValueForEdit) ? selectedValueForEdit.title : ''} id='title' placeholder={'Type the ' + category.toLowerCase() + ' here...'} label={category} onChange={(value) => { setDescription(value); setReset(false); }} reset={reset} required={true} />
                                                </>
                                            }
                                        </Form>
                                        <Button icon='save' labelPosition='left' content='Save' color='green' className='saveBtn' onClick={() => onSave()} />
                                    </>
                                }
                            </>
                        }
                        {operation === 'edit' && category === 'Profile data' &&
                            <>
                                <Form className='adminInputContainer' id='inputForm' autocomplete='off'>
                                    <FormGroup widths='equal'>
                                        <FormInput fluid label='Phone number' id='phone' type='text' placeholder='Enter phone number' defaultValue={dataToDisplay.profileData.phone} required className='adminInput' />
                                        <FormInput fluid label='Email' id='email' type='text' placeholder='Enter email' defaultValue={dataToDisplay.profileData.email} required className='adminInput' />
                                    </FormGroup>
                                    <FormGroup widths='equal'>
                                        <FormInput fluid label='Position' id='position' type='text' placeholder='Enter position' defaultValue={dataToDisplay.profileData.position} required className='adminInput textInput' />
                                        <FormInput fluid label='Department' id='department' type='text' placeholder='Enter department' defaultValue={dataToDisplay.profileData.department} required className='adminInput' />
                                    </FormGroup>
                                    <RichTextEditor id='university' placeholder='Enter university' label='University' data={dataToDisplay.profileData.university} required={true} onChange={(value) => { setUniversity(value); setReset(false); }} reset={reset} />
                                    <RichTextEditor id='description' placeholder='Type the description here...' label='Description' data={dataToDisplay.profileData.description} required={true} onChange={(value) => { setDescription(value); setReset(false); }} reset={reset} />
                                    <FormGroup widths='equal'>
                                        <FormInput fluid label='Scrolling text' id='scrollingText' type='text' placeholder='Enter scrolling text' defaultValue={dataToDisplay.profileData.scrollingText} className='adminInput' />
                                    </FormGroup>
                                    <FormGroup widths='equal'>
                                        <div className='field'>
                                            <FormInput fluid label='Education' placeholder='Enter education' id='education' />
                                            <Button icon='add' labelPosition='left' content='Add education' color='orange' className='addBtn' onClick={() => onAddEducation()} />
                                            {dataForOrder.length > 0 &&
                                                <>
                                                    <Table striped celled>
                                                        <TableHeader >
                                                            <TableRow>
                                                                <TableHeaderCell width='1' textAlign='center'>Order</TableHeaderCell>
                                                                <TableHeaderCell width='12' textAlign='center'>Title</TableHeaderCell>
                                                                <TableHeaderCell width='2' textAlign='center'>New order</TableHeaderCell>
                                                                <TableHeaderCell width='1' textAlign='center'>Remove</TableHeaderCell>
                                                            </TableRow>
                                                        </TableHeader>
                                                        <TableBody>
                                                            {dataForOrder.map((dataDis) => {
                                                                return (
                                                                    <>
                                                                        <TableRow>
                                                                            <TableCell textAlign='center'>{dataDis.order}</TableCell>
                                                                            <TableCell>{dataDis.title}</TableCell>
                                                                            <TableCell textAlign='center'>
                                                                                {dataDis.newOrder !== 0 &&
                                                                                    <div className='selectedOrder'>{dataDis.newOrder}<Icon name='remove circle' color='red' className='closeIcon' onClick={() => onOrderSelection('', dataDis.id)} /></div>
                                                                                }
                                                                                {dataDis.newOrder === 0 &&
                                                                                    <Dropdown
                                                                                        placeholder='Select order' className='adminInput orderDropdown'
                                                                                        fluid selection closeOnChange closeOnEscape closeOnBlur search
                                                                                        options={orderOptions} selectOnBlur={false} upward={false}
                                                                                        onChange={(event, data) => onOrderSelection(data.value, dataDis.id)} />
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell textAlign='center'><Icon name='remove' color='red' className='closeIcon' onClick={() => onRemoveEducation(dataDis.id)} /></TableCell>
                                                                        </TableRow>
                                                                    </>
                                                                )
                                                            })}
                                                        </TableBody>
                                                    </Table>
                                                </>
                                            }
                                        </div>
                                    </FormGroup>
                                    <UploadImage imageData={(val) => { setUploadedImageData(val); setReset(false); }} reset={reset} isEdit={operation === 'edit'} />
                                    {isUndefinedOrNull(uploadedImageData) &&
                                        <>
                                            <Image src={dataToDisplay.profileData.image} className='imagePreview' />
                                        </>
                                    }
                                    <FormInput fluid label='Upload profile file' id='file' type='file' onChange={(event) => setFileData(event.target.files[0])} className='adminInput' />
                                    {!isUndefinedOrNull(fileData) &&
                                        <>
                                            <div className='filePreview'>
                                                <a href={URL.createObjectURL(fileData)} target='_blank' rel='noreferrer'>{fileData.name}</a>
                                                <Icon className='fileCloseIcon' name='remove circle' size='large' color='red' onClick={() => onRemoveFile()} />
                                            </div>
                                        </>
                                    }
                                    {isUndefinedOrNull(fileData) && !isUndefinedOrNull(dataToDisplay.profileData.doc) &&
                                        <>
                                            <a href={dataToDisplay.profileData.doc} target='_blank' rel='noreferrer'>Profile document</a>
                                        </>
                                    }
                                </Form>
                                <Button icon='save' labelPosition='left' content='Save' color='green' className='saveBtn' onClick={() => onSave()} />
                            </>
                        }
                        {operation === 'delete' &&
                            <>
                                <Form className='adminInputContainer' autocomplete='off'>
                                    <FormDropdown
                                        placeholder={'Select ' + category.toLowerCase()} label={category}
                                        className='adminInput' fluid selection closeOnChange closeOnEscape closeOnBlur clearable search required
                                        options={dropdownList} selectOnBlur={false} upward={false} width='16'
                                        onChange={(event, data) => onEditDeleteItemSelection(data.value)}
                                        disabled={isUndefinedOrNull(dropdownList) || dropdownList.length === 0} />
                                </Form>
                                {!isUndefinedOrNull(selectedValueForEdit) &&
                                    <>
                                        <div>
                                            {(category === 'Academic position' || category === 'Research position') &&
                                                <>
                                                    <div>{category === 'Academic position' ? selectedValueForEdit.from + ' - ' + selectedValueForEdit.to : selectedValueForEdit.year}</div>
                                                    <Header as='h4' className='m-0'>{selectedValueForEdit.position}</Header>
                                                    <div dangerouslySetInnerHTML={{ __html: selectedValueForEdit.description }}></div>
                                                </>
                                            }
                                            {(category === 'Recognition' || category === 'Projects handled') &&
                                                <>
                                                    <div dangerouslySetInnerHTML={{ __html: selectedValueForEdit.title }}></div>
                                                </>
                                            }
                                        </div>
                                        <Button icon='trash' labelPosition='left' content='Delete' className='deleteBtn' color='red' onClick={() => onSave()} />
                                    </>
                                }
                            </>
                        }
                        {operation === 'order' &&
                            <>
                                <Reorder dataToBeOrdered={dataToDisplay[(category === 'Recognition' ? 'recognitions' : (category === 'Projects handled' ? 'projectsHandled' : ''))]} reset={reset} onSaveClick={(data) => { onSave(data); setReset(false); }} />
                            </>
                        }
                    </div>
                </div>
            }
        </>
    );
}

export default AdminRGB;